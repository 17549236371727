import { getTrackingAPI, putTrackingAPI } from '@/services/tracking';
import { useState } from 'react';

export default () => {
  const [tracking, setTracking] = useState<API.Tracking>();

  const getTracking = async (params: API.TrackingGetParameters) => {
    return getTrackingAPI(params).then((res) => {
      setTracking(res);
      return res;
    });
  };

  const updateTracking = async (params: API.TrackingPutParameter) => {
    return putTrackingAPI(params).then((res) => {
      setTracking(res);
      return res;
    });
  };

  return {
    getTracking,
    tracking,
    updateTracking,
  };
};
