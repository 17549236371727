import { getRequestOptionDemoMode } from '@/utils/demoMode';
import { request } from 'umi';

export const getTrackingAPI = async (params: API.TrackingGetParameters) => {
  return request<API.Tracking>('/tracking', {
    method: 'get',
    params: { id: params.id },
    ...getRequestOptionDemoMode(),
  });
};

export const putTrackingAPI = async (
  params: API.TrackingPutParameter,
  options?: API.RequestOptions,
) => {
  const { id, ...restParams } = params;
  return request<API.Tracking>('/tracking', {
    method: 'put',
    params: { id },
    data: {
      ...restParams,
    },
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

// /tracking/verify
export const verifyTrackingApi = async (
  params: API.TrackingVerifyParameters,
  options?: API.RequestOptions,
) => {
  return request<API.Tracking>('/tracking/verify', {
    method: 'put',
    params: { id: params.id },
    data: {
      url: params.url,
    },
    ...options,
    ...getRequestOptionDemoMode(),
  });
};
